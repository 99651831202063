import React, { useState, useRef } from 'react';
import {
    Box,
    Button,
    Divider,
    Stack,
    Typography
} from '@mui/material'
import ApiClient from './utils/ApiClient';
import XlsxUtil from './utils/XlsxUtil';
import Helpers from './utils/Helpers';
import UploadProgressMessage from './UploadProgressMessage';
import { SiMicrosoftexcel } from "react-icons/si";
import { ImCross } from "react-icons/im";
import { FiUploadCloud } from "react-icons/fi";

function FileUploader() {

    const MAX_FILE_SIZE_BYTES = XlsxUtil.MAX_FILE_SIZE_BYTES;
    const MAX_FILE_SIZE_MB = XlsxUtil.MAX_FILE_SIZE_MB;

    const [files, setFiles] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [uploadMessage, setUploadMessage] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0);
    const [companyCode, setCompanyCode] = useState("");
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const fileInputRef = useRef(null);

    const selectFiles = () => {
        fileInputRef.current.click();
    }

    const onFileBrowse = (e) => {
        const addedFiles = e.target.files;
        if (addedFiles.length === 0) return;

        setFiles([]);
        setUploadMessage(null);
        setUploadProgress(0);

        if (!XlsxUtil.isValidFileExtension(addedFiles[0].name)) {
            setUploadMessage({
                "level": "error",
                "text": Helpers.ERR_CODES["INVALID_FILE_EXTENSION"]
            });
            return;
        }

        if (addedFiles[0].size > MAX_FILE_SIZE_BYTES) {
            setUploadMessage({
                "level": "error",
                "text": `${Helpers.ERR_CODES["INVALID_FILE_SIZE"]} ${MAX_FILE_SIZE_MB} MB.`
            });
            return;
        }

        let file = addedFiles[0];
        file.url = URL.createObjectURL(file);
        // console.log('added file', file);

        if (!files.some((e) => e.name === file.name)) {
            XlsxUtil.setWorkbook(file)
                .then(() => {
                    return XlsxUtil.validateFileStructure(file);
                })
                .then(() => {
                    return XlsxUtil.isRequiredColumnPresent("FormData", "company_code");
                })
                .then((data) => {
                    // console.log('company_code: ', data);
                    setCompanyCode(data);
                    setFiles((prevFiles) => [file]);
                })
                .catch((err) => {
                    // console.error(err);
                    setUploadMessage({
                        "level": "error",
                        "text": err.toString()
                    });
                });
        }
    }

    const deleteFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        setUploadMessage(null);
    }

    const onDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
        e.dataTransfer.dropEffect = 'copy';
    }

    const onDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    }

    const onFileDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);

        setFiles([]);
        setUploadMessage(null);
        setUploadProgress(0);

        let addedFiles = e.dataTransfer.files;
        if (!XlsxUtil.isValidFileExtension(addedFiles[0].name)) {
            setUploadMessage({
                "level": "error",
                "text": Helpers.ERR_CODES["INVALID_FILE_EXTENSION"]
            });
            return;
        }

        if (addedFiles[0].size > MAX_FILE_SIZE_BYTES) {
            setUploadMessage({
                "level": "error",
                "text": `${Helpers.ERR_CODES["INVALID_FILE_SIZE"]} ${MAX_FILE_SIZE_MB} MB.`
            });
            return;
        }

        let file = addedFiles[0];
        file.url = URL.createObjectURL(file);

        // if (!files.some((e) => e.name === addedFiles[0].name)) {
        //     setFiles((prevFiles) => [addedFiles[0]]);
        // }

        if (!files.some((e) => e.name === file.name)) {
            XlsxUtil.setWorkbook(file)
                .then(() => {
                    return XlsxUtil.validateFileStructure(file);
                })
                .then(() => {
                    return XlsxUtil.isRequiredColumnPresent("FormData", "company_code");
                })
                .then((data) => {
                    setCompanyCode(data);
                    setFiles((prevFiles) => [file]);
                })
                .catch((err) => {
                    setUploadMessage({
                        "level": "error",
                        "text": err.toString()
                    });
                });
        }
    }

    const getCurrentTimestamp = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        return `${year}${month}${day}${hours}${minutes}${seconds}`;
    }

    const onFileUpload = (e) => {
        e.preventDefault();

        let newFileName = null;
        let nameTokens = files[0]?.name?.split('.');
        if (nameTokens.length > 1) {
            let baseName = nameTokens.slice(0, -1).join('.'); // Join all but the last token
            let extension = nameTokens[nameTokens.length - 1]; // Get the last token
            newFileName = `${baseName}_${getCurrentTimestamp()}.${extension}`;
        }

        if (!newFileName) return;

        // Create a new file with the updated name and the same type and last modified timestamp as the original file
        const fileToUpload = new File([files[0]], newFileName, {
            type: files[0].type,
            lastModified: files[0].lastModified
        });

        (async () => {
            await ApiClient.handleFileUpload(`files/${companyCode}`, fileToUpload,
                setUploadProgress, setUploadMessage);
        })();

        setIsSubmitDisabled(true);
        setTimeout(() => {
            setIsSubmitDisabled(false);
            setFiles([]);
        }, 5000);
    }

    return (
        <div className="file-upload-card">
            <div className="drag-area" onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onFileDrop}>
                {isDragging ? (
                    <span className="select">Drop file here</span>
                ) : (
                    <Box sx={{ m: 3 }}>
                        <Box>
                            <FiUploadCloud />
                            <Typography variant="h5">Drag and drop files here</Typography>
                        </Box>
                        <Divider sx={{ m: 1 }} />
                        <Box sx={{ mt: 1 }}>
                            <Button className='select' onClick={selectFiles}>Browse</Button>
                        </Box>

                        <Box sx={{ mt: 3 }}>
                            <span>Maximum upload file size: {MAX_FILE_SIZE_MB} MB.</span>
                        </Box>
                    </Box>
                )}
                <input name="file" className="file" type="file" ref={fileInputRef} onChange={onFileBrowse}></input>
            </div>
            <div className="files-viewer-container">
                {files.map((file, index) => (
                    <Box key={index} sx={{ mr: 2, mb: 1, display: 'flex', alignItems: 'center', flex: '0 0 calc(95%)' }}>
                        <Box sx={{ mr: 2 }} className="image" key={index}>
                            <SiMicrosoftexcel />
                        </Box>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <Typography>{file.name}</Typography>
                            <progress className="file-upload-progress-bar" value={uploadProgress} max="100"></progress>
                        </Box>
                        <ImCross className="delete-image-icon" onClick={() => deleteFile(index)} />
                    </Box>
                ))}
            </div>

            <UploadProgressMessage uploadMessage={uploadMessage} />

            <Stack direction="row" justifyContent="flex-end" spacing={0} sx={{ p: 1, mt: 2 }}>
                <Button onClick={onFileUpload} disabled={isSubmitDisabled || (files && files.length !== 1)}>
                    Submit
                </Button>
            </Stack>
        </div>
    )
}

export default FileUploader;
