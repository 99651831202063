import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Typography
} from '@mui/material'
import ApiClient from './utils/ApiClient';
import Progress from './Progress';
import GetAppIcon from '@mui/icons-material/GetApp';
import moment from 'moment';

function DataFiles({ userCompanyDomain }) {
    const [records, setRecords] = useState([]);
    const [paginatedRecords, setPaginatedRecords] = useState([]);
    const [pageLoaded, setPageLoaded] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Trigger this on page load and file submission success.
    useEffect(() => {
        loadTableData();
    }, []);

    // Triggers on page pagination change
    useEffect(() => {
        setPaginatedRecords(records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    }, [page, rowsPerPage]);

    const loadTableData = async () => {
        setPageLoaded(false);

        let apiResponse = await ApiClient.handleGET(`files?domain=${userCompanyDomain}`);
        // console.log("records", apiResponse);

        const uniqueRecords = apiResponse?.files?.filter((rec, index, self) => index === self.findIndex((s) => s.id === rec.id));

        setRecords(uniqueRecords || []);
        setPaginatedRecords(uniqueRecords.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
        setPageLoaded(true);
    }

    const handleDownload = (downloadUrl = null) => {
        if (!downloadUrl)
            return;
        window.location.href = downloadUrl;
    };

    return (
        <>
            <Divider />

            {!pageLoaded &&
                <Progress text="Loading table. This might take a few seconds" />
            }

            {pageLoaded && paginatedRecords.length > 0 &&
                <TableContainer className="submissions" sx={{ mt: 3 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="records table">
                        {/* <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>File</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead> */}
                        <TableBody>
                            {paginatedRecords.map((sb) => (
                                <TableRow key={sb.id} >
                                    {/* <TableCell style={{ width: '30' }}>{moment(sb.uploaded_on).format('YYYY-MM-DD')}</TableCell> */}
                                    <TableCell style={{ width: '80%' }}>{sb.filename}</TableCell>
                                    <TableCell style={{ width: 'auto' }}>
                                        <Button className="download" onClick={() => handleDownload(sb.download_url)}><GetAppIcon />&nbsp;Download</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={records.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            }

            {pageLoaded && records.length === 0 &&
                <Box sx={{ mt: 3 }}>
                    <Typography textAlign={'center'}>No data to display.</Typography>
                </Box>
            }
        </>
    );
}

export default DataFiles;