import './styles/App.css';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { AccountContext } from "./auth/Account";
import FileUploader from './FileUploader'
import Submissions from './Submissions'
import DataFiles from './DataFiles'
import SubmissionLog from './SubmissionLog'
import MainAppBar from './MainAppBar';
import AppFooter from './AppFooter';
import Progress from './Progress';

import {
    Box,
    Typography,
    Tabs,
    Tab
} from '@mui/material'

/**
 * Represents the Dashboard component.
 * @returns {JSX.Element} The rendered Dashboard component.
 */
function Dashboard() {
    const navigate = useNavigate();
    const [userCompanyDomain, setUserCompanyDomain] = useState(null);
    const [isDataReader, setIsDataReader] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    // Handle tab change
    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    const { getSession } = useContext(AccountContext);

    const ENV = (process.env?.REACT_APP_ENV === "local" || process.env?.REACT_APP_ENV === "dev") ? process.env?.REACT_APP_ENV : "";

    useEffect(() => {
        getSession().then(async (session) => {
            if (!session) {
                navigate('/');
            }
            // console.log('domain', session.attributes['custom:company_domain'], 'is data reader', session.attributes['custom:is_data_reader']);

            setUserCompanyDomain(session.attributes['custom:company_domain']);
            setIsDataReader(session.attributes['custom:is_data_reader'] === "1");
            setPageLoaded(true);
        }).catch(error => {
            console.error('Error fetching session:', error);
            navigate('/');
        });
    }, []);

    return pageLoaded ? (
        <>
            <MainAppBar />
            <Box className="main-container">
                <Typography
                    variant="h3"
                    sx={{ display: { xs: 'none', sm: 'block' }, fontWeight: 700, fontSize: '35px', textShadow: '2px 2px 8px rgba(0,0,0,0.1)' }}
                >
                    EIIRP Program Dashboard {ENV !== "" && <span style={{ "color": "red" }}>(env: {ENV})</span>}
                </Typography>

                <Tabs
                    className="tabs"
                    value={tabIndex} onChange={handleTabChange}>
                    <Tab
                        className="tab-item"
                        label="File Uploader" />
                    <Tab
                        className="tab-item"
                        label="Submission Log" />

                    {isDataReader &&
                        <Tab
                            className="tab-item"
                            label="Data Files" />
                    }
                </Tabs>

                {tabIndex === 0 && (
                    <>
                        <Box sx={{ mt: 2 }} >
                            <FileUploader />
                        </Box>

                        <Box sx={{ mt: 2 }} >
                            <Submissions userCompanyDomain={userCompanyDomain} />
                        </Box>
                    </>
                )}
                {tabIndex === 1 && (
                    <Box sx={{ mt: 2 }}>
                        <SubmissionLog userCompanyDomain={userCompanyDomain} />
                    </Box>
                )}

                {tabIndex === 2 && (
                    <Box sx={{ mt: 2 }}>
                        <DataFiles userCompanyDomain={userCompanyDomain} />
                    </Box>
                )}
            </Box>
            <AppFooter />
        </>
    ) : <Progress text="Preparing your dashboard..." />;
}

export default Dashboard;