import React, { useEffect, useState } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    Typography
} from '@mui/material'
import moment from 'moment';
import ApiClient from './utils/ApiClient';
import Progress from './Progress';

function SubmissionLog({ userCompanyDomain }) {
    const [formTypes, setFormTypes] = useState([]);
    const [reportMonths, setReportMonths] = useState([]);
    const [pageLoaded, setPageLoaded] = useState(false);

    const MONTHS_PERIOD = 5;

    // Trigger this on page load and file submission success.
    useEffect(() => {
        loadTableData();
        setReportMonths(createReportMonths());
    }, []);

    const createReportMonths = () => {
        const labels = [];
        for (let i = MONTHS_PERIOD; i >= 0; i--) {
            let m = moment().subtract(i, 'months');
            labels.push({
                'label': m.format('MMMM').substring(0, 3),
                'code': m.format('YYYYMM'),
            });
        }
        return labels;
    }

    const loadTableData = async () => {
        setPageLoaded(false);

        let apiResponse = await ApiClient.handleGET(`submission-log?domain=${userCompanyDomain}`);
        // console.log("apiResponse", apiResponse);

        setFormTypes(apiResponse?.formTypes || []);
        setPageLoaded(true);
    }

    return (
        <>
            {!pageLoaded &&
                <Progress />
            }

            {pageLoaded && formTypes.length > 0 &&
                <Grid className="submission-log-grid" container spacing={2}>
                    {formTypes.map((s, index) => (
                        s.forms.length > 0 && <Grid item xs={12} md={6} key={index}>
                            <Box key={index} sx={{ p: 2, border: '1px solid #e0e0e0', borderRadius: '8px' }}>
                                <Typography variant="h6" gutterBottom>{s?.dbedt_id} {s?.name}</Typography>
                                <TableContainer className="data" sx={{ mt: 3 }}>
                                    <Table aria-label="log table">
                                        <TableHead sx={{ background: '#eeeef1' }}>
                                            <TableRow>
                                                {reportMonths.map((m, index) => (
                                                    <TableCell key={index}>{m.label}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {reportMonths.map((m, index) => {
                                                    let count = s.forms.filter(f => f.report_period === m.code)?.length;
                                                    return (
                                                        <TableCell
                                                            sx={{
                                                                background: `${count > 0 ? '#229a2980' : '#ff00006e'}`
                                                            }}
                                                            key={index}
                                                        >{count}</TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            }
        </>
    );
}

export default SubmissionLog;